<template>
  <v-card class="border-16 blog-card text-left">
    <v-img
      class="image"
      height="260"
      :src="item.img"
    ></v-img>
    <v-card-text class="d-flex align-center mb-0 pb-0 pt-5">
      <img height="20" width="20" :src="clock" />
      <span class="ml-2 date-text">{{ item.date }}</span>
    </v-card-text>
    <v-card-title class="title py-4 my-1" @click="handleClickItem(item.id)">{{
      $t(item.title)
    }}</v-card-title>
    <v-card-text class="text py-0 pb-4">
      <span>
        {{ $t(item.description) }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import clock from "@/assets/img/clock.svg";

export default {
  name: "BlogItem",
  props: {
    item: Object,
  },
  data: () => ({
    clock,
  }),
  methods: {
    handleClickItem(val) {
      this.$router.push(`/blog/${val}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/style/style";

.blog-card {
  box-shadow: 0 4px 24px rgba(34, 41, 51, 0.1) !important;
  height: auto !important;

  .image {
    cursor: pointer;
  }

  span {
    font-size: 16px;
  }

  .title {
    color: #11263c;
    font-weight: bolder;
    font-family: "Raleway", sans-serif !important;
  }

  .title:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .text span {
    color: #000000;
    font-size: 16px;
  }

  .c-footer {
    span {
      font-family: sans-serif !important;
      color: #828282;
    }
  }

  .date-text {
    font-family: sans-serif !important;
  }
}
</style>
